// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-behandelingen-js": () => import("./../../../src/pages/behandelingen.js" /* webpackChunkName: "component---src-pages-behandelingen-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-praktisch-js": () => import("./../../../src/pages/praktisch.js" /* webpackChunkName: "component---src-pages-praktisch-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

